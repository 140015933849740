<template>
    <div>
        <div class="grey-bg row">
            <div class="col-5">
                <span class="book">Artikel:</span>
            </div>
            <div class="col-7 text-right">
                <span class="book">Geschenkgutschein</span>
            </div>
            <div class="col-6">
                <span class="book">Einzelpreis:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">{{total}} €</span>
            </div>
            <div class="col-6">
                <span class="book">Anzahl:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">1</span>
            </div>
            <div class="col-6">
                <span class="book">MwSt.:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">19 %</span>
            </div>
            <div class="col-6">
                <span class="book">Gesamtpreis:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">{{total}} €</span>
            </div>
            <div class="col-12">
                <hr>
            </div>
            <div class="col-6">
                <span class="book">Summe Artikel (netto):</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">{{totalWithoutTax}} €</span>
            </div>
            <div class="col-6">
                <span class="book">zzgl. MwSt. 19 %:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">{{totalTax}} €</span>
            </div>
            <div class="col-6">
                <span class="book">Summe Artikel (brutto):</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">{{total}} €</span>
            </div>
             <div class="col-12">
                <hr class="black-hr">
            </div>
            <div class="col-6">
                <span class="demi">Gesamtsumme</span>
            </div>
            <div class="col-6 text-right">
                <span class="demi">{{total}} €</span>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'CouponCartTable',
        data() {
            return {
                cart: this.$store.state.cart,
            }
        },
        computed: {
            total() {
                var val = this.cart.couponValue.replace(",", ".")
                return parseFloat(val)
            },
            totalTax(){
                return (this.total * (19/(100+19))).toFixed(2)
            },
            totalWithoutTax(){
                return this.total - this.totalTax
            },
        },
        methods: {
        },
    }
</script>
<style lang="scss" scoped>

    .grey-bg{
        padding:25px;

        span{
            display: inline-block;
        }

        .light{
            padding-top:1rem;
            padding-bottom:1rem;
        }
    }


</styles>