<template>
    <div>
        <div class="row grey-bg">
            <div class="col-5">
                <span class="book">Artikel:</span>
            </div>
            <div class="col-7 text-right">
                <span class="book">Reservierungskosten</span>
            </div>
            <div class="col-12">
                <span class="light">
                    Bei der Buchung erfolgt eine Anzahlung in Höhe von 39,90 € vom Gesamtbetrag. {{ totalText}} Sie können ihre Buchung kostenlos bis zu 24 Stunden vor Terminbeginn unter <a href="mailto:storno@waschprofis.de">storno@waschprofis.de</a> stornieren.
                </span>
            </div>
            <div class="col-6">
                <span class="book">Einzelpreis:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">39,90 €</span>
            </div>
            <div class="col-6">
                <span class="book">Anzahl:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">1</span>
            </div>
            <div class="col-6">
                <span class="book">MwSt.:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">19 %</span>
            </div>
            <div class="col-6">
                <span class="book">Gesamtpreis:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">39,90</span>
            </div>
            <div class="col-12">
                <hr>
            </div>
            <div class="col-6">
                <span class="book">Summe Artikel (netto):</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">33,57 €</span>
            </div>
            <div class="col-6">
                <span class="book">zzgl. MwSt. 19 %:</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">6,38 €</span>
            </div>
            <div class="col-6">
                <span class="book">Summe Artikel (brutto):</span>
            </div>
            <div class="col-6 text-right">
                <span class="book">39,90 €</span>
            </div>
             <div class="col-12">
                <hr class="black-hr">
            </div>
            <div class="col-6">
                <span class="demi">Gesamtsumme</span>
            </div>
            <div class="col-6 text-right">
                <span class="demi">39,90 €</span>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'CartTableXS',
        props: ['coupon'],
        data() {
            return {
                car_type: this.$store.state.cart.product.split("_")[0],
                cleaning_type: this.$store.state.cart.product.split("_")[1],
                additional_service: this.$store.state.cart.additional_service,
                cart: this.$store.state.cart,
                actionWeek: false
            }
        },
        computed: {
          items() { return this.$store.state.items },
          totalText(){
              var cleaning = this.items[this.car_type][this.cleaning_type].price,
                  additional_service = this.items[this.car_type][this.cleaning_type].additional_service,
                  additional = 0,
                  hasCaramic = false;

              if(this.additional_service.length > 0){
                  this.additional_service.forEach(value=>{
                      additional += additional_service[value].price;
                      if(value === "Keramikversiegelung"){
                            hasCaramic = true;
                          }
                  })
              }

              var price = parseFloat(cleaning + additional);

              if(this.coupon){
                if(this.coupon.indexOf("%") !== -1){
                    price = price - ((price * this.coupon.replace('%', '') ) / 100)
                }else{
                    price = parseFloat(price - this.coupon).toFixed(2)
                }
              }

              if(this.actionWeek && this.cleaning_type === "outside" && hasCaramic){

                price = (price * 75 ) / 100;
              }

              price = parseFloat(price - 39.90).toFixed(2);

              if(price < 0) {
                return `Diese wird Ihnen bei der Abholung ihres Fahrzeuges erstattet.`;
              }
              return `Die Restsumme (${price}) € wird bei Abholung ihres Fahrzeugs fällig.`;
          },
        },
        mounted() {
            if(new Date() < new Date('August 15, 2021 23:59:59')){
                this.actionWeek = true;
            }
        }
    }
</script>
<style lang="scss" scoped>

    .grey-bg{
        padding:25px;

        span{
            display: inline-block;
        }

        .light{
            font-size: 14px;
            padding-top:1rem;
            padding-bottom:1rem;
            color: #969696;
        }
    }


</styles>