var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row grey-bg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"light"},[_vm._v(" Bei der Buchung erfolgt eine Anzahlung in Höhe von 39,90 € vom Gesamtbetrag. "+_vm._s(_vm.totalText)+" Sie können ihre Buchung kostenlos bis zu 24 Stunden vor Terminbeginn unter "),_c('a',{attrs:{"href":"mailto:storno@waschprofis.de"}},[_vm._v("storno@waschprofis.de")]),_vm._v(" stornieren. ")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('span',{staticClass:"book"},[_vm._v("Artikel:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-7 text-right"},[_c('span',{staticClass:"book"},[_vm._v("Reservierungskosten")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Einzelpreis:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("39,90 €")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Anzahl:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("MwSt.:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("19 %")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Gesamtpreis:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("39,90")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Summe Artikel (netto):")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("33,57 €")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("zzgl. MwSt. 19 %:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("6,38 €")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Summe Artikel (brutto):")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("39,90 €")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr',{staticClass:"black-hr"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"demi"},[_vm._v("Gesamtsumme")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"demi"},[_vm._v("39,90 €")])])
}]

export { render, staticRenderFns }