var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"grey-bg row"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v(_vm._s(_vm.total)+" €")])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v(_vm._s(_vm.total)+" €")])]),_vm._m(8),_vm._m(9),_c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v(_vm._s(_vm.totalWithoutTax)+" €")])]),_vm._m(10),_c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v(_vm._s(_vm.totalTax)+" €")])]),_vm._m(11),_c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v(_vm._s(_vm.total)+" €")])]),_vm._m(12),_vm._m(13),_c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"demi"},[_vm._v(_vm._s(_vm.total)+" €")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('span',{staticClass:"book"},[_vm._v("Artikel:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-7 text-right"},[_c('span',{staticClass:"book"},[_vm._v("Geschenkgutschein")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Einzelpreis:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Anzahl:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("MwSt.:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"book"},[_vm._v("19 %")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Gesamtpreis:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Summe Artikel (netto):")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("zzgl. MwSt. 19 %:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"book"},[_vm._v("Summe Artikel (brutto):")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr',{staticClass:"black-hr"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('span',{staticClass:"demi"},[_vm._v("Gesamtsumme")])])
}]

export { render, staticRenderFns }